<script>
export default {
  data() {
    return {
      hover: false,
    };
  }
}
</script>

<template>
  <div @mouseover="hover = true"
       @mouseleave="hover = false">
    <svg v-show="!hover" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="32"
         height="32"
         viewBox="0 0 32 32">
      <defs>
        <clipPath id="facebook-clip-path">
          <rect width="32" height="32" fill="#707070"/>
        </clipPath>
      </defs>
      <g clip-path="url(#facebook-clip-path)">
        <path
            d="M32.141,16.216A16.07,16.07,0,1,0,13.56,32.187V20.89H9.482V16.216h4.08V12.654c0-4.052,2.4-6.291,6.07-6.291a24.532,24.532,0,0,1,3.6.316v3.979H21.2a2.33,2.33,0,0,0-2.619,2.525v3.033h4.457l-.713,4.674H18.582v11.3A16.143,16.143,0,0,0,32.141,16.216"
            transform="translate(-0.234 -0.118)" fill="#707070"/>
      </g>
    </svg>

    <svg v-show="hover" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="32" height="32" viewBox="0 0 32 32">
      <defs>
        <clipPath id="facebook-hover-clip-path">
          <rect width="32" height="32" fill="#3b5998"/>
        </clipPath>
      </defs>
      <g clip-path="url(#facebook-hover-clip-path)">
        <path d="M32.141,16.216A16.07,16.07,0,1,0,13.56,32.187V20.89H9.482V16.216h4.08V12.654c0-4.052,2.4-6.291,6.07-6.291a24.532,24.532,0,0,1,3.6.316v3.979H21.2a2.33,2.33,0,0,0-2.619,2.525v3.033h4.457l-.713,4.674H18.582v11.3A16.143,16.143,0,0,0,32.141,16.216" transform="translate(-0.234 -0.118)" fill="#3b5998"/>
      </g>
    </svg>
  </div>
</template>
