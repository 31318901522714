<script>
export default {
  data() {
    return {
      hover: false,
    };
  }
}
</script>

<template>
  <div @mouseover="hover = true"
       @mouseleave="hover = false">
    <svg v-show="!hover" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="33"
         height="32" viewBox="0 0 33 32">
      <defs>
        <clipPath id="twitter-clip-path">
          <rect width="33" height="32" fill="#707070"/>
        </clipPath>
      </defs>
      <g transform="translate(0)" clip-path="url(#twitter-clip-path)">
        <path
            d="M15.99.2a15.99,15.99,0,1,0,15.99,15.99A15.99,15.99,0,0,0,15.99.2m8.03,12.958q.011.247.011.5A10.962,10.962,0,0,1,7.158,22.9a7.992,7.992,0,0,0,.919.053,7.729,7.729,0,0,0,4.787-1.65,3.856,3.856,0,0,1-3.6-2.677A3.837,3.837,0,0,0,11,18.556a3.857,3.857,0,0,1-3.092-3.777V14.73a3.825,3.825,0,0,0,1.746.482A3.857,3.857,0,0,1,8.465,10.07,10.943,10.943,0,0,0,16.409,14.1a3.857,3.857,0,0,1,6.569-3.515,7.727,7.727,0,0,0,2.448-.935,3.869,3.869,0,0,1-1.7,2.129,7.678,7.678,0,0,0,2.214-.607,7.83,7.83,0,0,1-1.923,2"
            transform="translate(0.191 -0.109)" fill="#707070"/>
      </g>
    </svg>
    <svg v-show="hover" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="33" height="32" viewBox="0 0 33 32">
      <defs>
        <clipPath id="twitter-hover-clip-path">
          <rect width="33" height="32" fill="#00acee"/>
        </clipPath>
      </defs>
      <g clip-path="url(#twitter-hover-clip-path)">
        <path d="M15.99.2a15.99,15.99,0,1,0,15.99,15.99A15.99,15.99,0,0,0,15.99.2m8.03,12.958q.011.247.011.5A10.962,10.962,0,0,1,7.158,22.9a7.992,7.992,0,0,0,.919.053,7.729,7.729,0,0,0,4.787-1.65,3.856,3.856,0,0,1-3.6-2.677A3.837,3.837,0,0,0,11,18.556a3.857,3.857,0,0,1-3.092-3.777V14.73a3.825,3.825,0,0,0,1.746.482A3.857,3.857,0,0,1,8.465,10.07,10.943,10.943,0,0,0,16.409,14.1a3.857,3.857,0,0,1,6.569-3.515,7.727,7.727,0,0,0,2.448-.935,3.869,3.869,0,0,1-1.7,2.129,7.678,7.678,0,0,0,2.214-.607,7.83,7.83,0,0,1-1.923,2" transform="translate(0.191 -0.109)" fill="#00acee"/>
      </g>
    </svg>
  </div>
</template>
