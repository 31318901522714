import { createApp } from 'vue/dist/vue.esm-bundler'
import App from './App.vue'
import router from './router'
import i18n from "./i18n"
import { createStore } from 'vuex'
import VueClickAway from "vue3-click-away";
import { vIntersectionObserver } from '@vueuse/components'

import './assets/css/app.css'

const store = createStore({
    state () {
        return {
            level1Filter: 'dimensions',
            level2Filter: {title: null, key: null},
            openOverlay : false,
            overlayData : null,
        }
    },
    mutations: {
        changeLevel1Filter (state, filter) {
            state.level1Filter = filter
        },
        changeLevel2Filter (state, filter) {
            state.level2Filter = filter
        },
        toggleOverlay(state) {
            state.openOverlay = !state.openOverlay;

        },
        setOverlayData(state, data) {
            state.overlayData = data;
        }
    }
})

const app = createApp(App)
app.config.unwrapInjectedRef = true

app.use(router)
app.use(i18n)
app.use(store)
app.use(VueClickAway)
app.directive('intersection-observer', vIntersectionObserver)

app.mount('#app')
