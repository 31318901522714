<script>
export default {
  data() {
    return {
      hover: false,
    };
  }
}
</script>

<template>
  <div @mouseover="hover = true"
       @mouseleave="hover = false">
    <svg v-show="!hover" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="45.916" height="32.141"
         viewBox="0 0 45.916 32.141">
      <defs>
        <clipPath id="youtube-clip-path">
          <rect width="45.916" height="32.141" fill="#707070"/>
        </clipPath>
      </defs>
      <g clip-path="url(#youtube-clip-path)">
        <path
            d="M44.956,5.019A5.75,5.75,0,0,0,40.9.959C37.316,0,22.957,0,22.957,0S8.6,0,5.019.959A5.752,5.752,0,0,0,.959,5.019C0,8.6,0,16.07,0,16.07s0,7.473.959,11.052a5.752,5.752,0,0,0,4.059,4.059c3.579.959,17.939.959,17.939.959s14.359,0,17.942-.959a5.752,5.752,0,0,0,4.059-4.059c.957-3.579.957-11.052.957-11.052s0-7.473-.959-11.052M18.366,22.957V9.183L30.293,16.07Z"
            fill="#707070"/>
      </g>
    </svg>
    <svg v-show="hover" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="45.916" height="32.141"
         viewBox="0 0 45.916 32.141">
      <defs>
        <clipPath id="youtube-hover-clip-path">
          <rect width="45.916" height="32.141" fill="red"/>
        </clipPath>
      </defs>
      <g clip-path="url(#youtube-hover-clip-path)">
        <path d="M44.956,5.019A5.75,5.75,0,0,0,40.9.959C37.316,0,22.957,0,22.957,0S8.6,0,5.019.959A5.752,5.752,0,0,0,.959,5.019C0,8.6,0,16.07,0,16.07s0,7.473.959,11.052a5.752,5.752,0,0,0,4.059,4.059c3.579.959,17.939.959,17.939.959s14.359,0,17.942-.959a5.752,5.752,0,0,0,4.059-4.059c.957-3.579.957-11.052.957-11.052s0-7.473-.959-11.052M18.366,22.957V9.183L30.293,16.07Z"
              fill="red"/>
      </g>
    </svg>
  </div>
</template>
