import { createRouter, createWebHistory, RouterView } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Translation from "@/i18n/translation"

const router = createRouter({
  history: createWebHistory(import.meta.env.VITE_BASE_URL),
  routes: [
    {
      path: "/:locale?",
      component: RouterView,
      beforeEnter: Translation.routeMiddleware,
      children: [
        {
          path: '',
          name: 'de.home',
          component: HomeView
        },
        {
          path: 'kontakt',
          name: 'de.contact',
          component: () => import('../views/ContactView.vue')
        },
        {
          path: 'datenschutz',
          name: 'de.privacy',
          component: () => import('../views/PrivacyView.vue')
        },
        {
          path: 'impressum',
          name: 'de.imprint',
          component: () => import('../views/ImprintView.vue')
        },
        {
          path: '',
          name: 'en.home',
          component: HomeView
        },
        {
          path: 'contact',
          name: 'en.contact',
          component: () => import('../views/ContactView.vue')
        },
        {
          path: 'privacy',
          name: 'en.privacy',
          component: () => import('../views/PrivacyView.vue')
        },
        {
          path: 'imprint',
          name: 'en.imprint',
          component: () => import('../views/ImprintView.vue')
        },
        {
          path: 'embed/:id',
          name: 'embed',
          component: () => import('../views/EmbedView.vue')
        },
      ]
    },
    { path: '/en/kontakt', redirect: { path: '/en/contact' } },
    { path: '/en/impressum', redirect: { path: '/en/imprint' } },
    { path: '/en/datenschutz', redirect: { name: '/en/privacy' } },
    { path: '/contact', redirect: { name: 'de.contact' } },
    { path: '/imprint', redirect: { name: 'de.imprint' } },
    { path: '/privacy', redirect: { name: 'de.privacy' } },
  ]
})

export default router
