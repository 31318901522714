<script>
import {mapState} from "vuex";
import Slider from "@/components/overlay/parts/Slider.vue";

export default {
  components: {Slider},
  computed: {
    ...mapState({
      overlayData: 'overlayData',
    })
  },
  data: () => {
    return {
      linkRegex: /(https?\:\/\/)?(www\.)?[^\s]+\.[^\s]+/g,
    }
  },
  methods: {
    replaceLinks(matched) {
      let withProtocol = matched

      if (!withProtocol.startsWith("http")) {
        withProtocol = "http://" + matched
      }

      return `<a target="_blank" class="font-bold hover:text-red-300 transition-colors duration-200" href="${withProtocol}">${matched}</a>`
    }
  }
}
</script>

<template>
  <div class="px-6 xl:px-20 py-8 flex flex-col gap-10">
    <div v-if="overlayData.fliptext1 || overlayData.flipimage1" id="section-achievement">
      <div class="text-3xl md:text-4xl font-bold mb-4">{{ $t('overlay.background') }}</div>
      <div class="flex flex-col md:flex-row gap-10">
        <div v-if="overlayData.fliptext1" class="md:basis-1/2 whitespace-break-spaces order-2 md:order-1"
             v-html="overlayData.fliptext1"></div>
        <div v-if="overlayData.flipimage1" class="relative md:basis-1/2 order-1 md:order-2 h-fit">
          <picture>
            <source
                :srcset="overlayData.flipimage1 + '-496px.' + overlayData.flipimage1extension"
                media="(min-width: 1520px)">
            <source
                :srcset="overlayData.flipimage1 + '-372px.' + overlayData.flipimage1extension"
                media="(min-width: 1280px)">
            <source
                :srcset="overlayData.flipimage1 + '-353px.' + overlayData.flipimage1extension"
                media="(min-width: 1024px)">
            <source
                :srcset="overlayData.flipimage1 + '-468px.' + overlayData.flipimage1extension"
                media="(min-width: 768px)">
            <source
                :srcset="overlayData.flipimage1 + '-690px.' + overlayData.flipimage1extension"
                media="(min-width: 640px)">
            <source
                :srcset="overlayData.flipimage1 + '-592px.' + overlayData.flipimage1extension"
                media="(min-width: 390px)">
            <img :src="overlayData.flipimage1 + '-342px.'  + overlayData.flipimage1extension" :alt="overlayData.header"
                 class="aspect-[3/2] w-full object-cover">
          </picture>
          <div v-if="overlayData.flipimage1copyright"
              class="absolute bg-gray-200 text-xs py-1 px-2 text-grey-500 bottom-0 left-0 z-[10] max-w-full hover:max-h-[300px] line-clamp-1 hover:line-clamp-none max-h-6 transition-[max-height] duration-300">
            {{$t('general.copyright')}}{{overlayData.flipimage1copyright}}
          </div>
        </div>
      </div>
    </div>

  </div>

  <div
      v-if="overlayData.slidertext1 || overlayData.slidertext2 || overlayData.slidertext3 || overlayData.slidertext4 || overlayData.slidertext5 || overlayData.slidertitle1 || overlayData.slidertitle2 || overlayData.slidertitle3 || overlayData.slidertitle4 || overlayData.slidertitle5 || overlayData.sliderimage1 || overlayData.sliderimage2 || overlayData.sliderimage3 || overlayData.sliderimage4 || overlayData.sliderimage5"
      id="section-engagement">
    <div class="px-6 xl:px-20">
      <div class="text-3xl md:text-4xl font-bold mb-4">{{ $t('overlay.engagement') }}</div>
    </div>
    <Slider></Slider>
  </div>

  <div class="px-6 xl:px-20 py-8 flex flex-col gap-10">

    <div v-if="overlayData.fliptext3 || overlayData.flipimage3 " id="section-contribution">
      <div class="text-3xl md:text-4xl font-bold mb-4">{{ $t('overlay.contribution') }}</div>
      <div class="flex flex-col md:flex-row gap-10">
        <div v-if="overlayData.fliptext3" class="md:basis-1/2 whitespace-break-spaces order-2 md:order-1"
             v-html="overlayData.fliptext3"></div>
        <div v-if="overlayData.flipimage3" class="relative h-fit md:basis-1/2 order-1 md:order-2">
          <picture>
            <source
                :srcset="overlayData.flipimage3 + '-496px.' + overlayData.flipimage3extension"
                media="(min-width: 1520px)">
            <source
                :srcset="overlayData.flipimage3 + '-372px.' + overlayData.flipimage3extension"
                media="(min-width: 1280px)">
            <source
                :srcset="overlayData.flipimage3 + '-353px.' + overlayData.flipimage3extension"
                media="(min-width: 1024px)">
            <source
                :srcset="overlayData.flipimage3 + '-468px.' + overlayData.flipimage3extension"
                media="(min-width: 768px)">
            <source
                :srcset="overlayData.flipimage3 + '-690px.' + overlayData.flipimage3extension"
                media="(min-width: 640px)">
            <source
                :srcset="overlayData.flipimage3 + '-592px.' + overlayData.flipimage3extension"
                media="(min-width: 390px)">
            <img :src="overlayData.flipimage3 + '-342px.' + overlayData.flipimage3extension" :alt="overlayData.header"
                 class="aspect-[3/2] w-full object-cover">
          </picture>
          <div v-if="overlayData.flipimage3copyright"
               class="absolute bg-gray-200 text-xs py-1 px-2 text-grey-500 bottom-0 left-0 z-[10] max-w-full hover:max-h-[300px] line-clamp-1 hover:line-clamp-none max-h-6 transition-[max-height] duration-300">
            {{$t('general.copyright')}}{{overlayData.flipimage3copyright}}
          </div>
        </div>
      </div>
    </div>

    <div v-if="overlayData.download" class="w-full h-0 border-[1px] border-t-gray-500"></div>
  </div>
</template>
