import i18n from "@/i18n"
import { nextTick } from "vue"

const Translation = {
    get currentLocale() {
        return i18n.global.locale.value
    },
    i18nRoute(to) {
        let currentLocale = Translation.currentLocale;

        if(Translation.currentLocale === 'de') {
            currentLocale = '';
        }

        return {
            ...to,
            params: {
                locale: currentLocale,
                ...to.params
            }
        }
    },
    async routeMiddleware(to, _from, next) {
        const paramLocale = to.params.locale
        // if(!Translation.isLocaleSupported(paramLocale)) {
        //     return next(Translation.guessDefaultLocale())
        // }
        if(paramLocale === 'en') {
            await Translation.switchLanguage(paramLocale)
        }
        return next()
    },
    get defaultLocale() {
        return import.meta.env.VITE_DEFAULT_LOCALE
    },
    guessDefaultLocale() {
        const userPersistedLocale = Translation.getPersistedLocale()
        if(userPersistedLocale) {
            return userPersistedLocale
        }
        const userPreferredLocale = Translation.getUserLocale()
        if (Translation.isLocaleSupported(userPreferredLocale.locale)) {
            return userPreferredLocale.locale
        }
        if (Translation.isLocaleSupported(userPreferredLocale.localeNoRegion)) {
            return userPreferredLocale.localeNoRegion
        }

        return Translation.defaultLocale
    },
    isLocaleSupported(locale) { // <--- 1
        return Translation.supportedLocales.includes(locale)
    },
    getUserLocale() { // <--- 2
        const locale = window.navigator.language ||
            window.navigator.userLanguage ||
            Translation.defaultLocale
        return {
            locale: locale,
            localeNoRegion: locale.split('-')[0]
        }
    },
    getPersistedLocale() { // <--- 3
        const persistedLocale = localStorage.getItem("user-locale")
        if(Translation.isLocaleSupported(persistedLocale)) {
            return persistedLocale
        } else {
            return null
        }
    },
    get supportedLocales() {
        return import.meta.env.VITE_SUPPORTED_LOCALES.split(",")
    },
    set currentLocale(newLocale) {
        i18n.global.locale.value = newLocale
    },
    async switchLanguage(newLocale) {
        await Translation.loadLocaleMessages(newLocale)
        Translation.currentLocale = newLocale
        document.querySelector("html").setAttribute("lang", newLocale)
        localStorage.setItem("user-locale", newLocale)
    },
    async loadLocaleMessages(locale) {
        if(!i18n.global.availableLocales.includes(locale)) {
            const messages = await import(`@/i18n/locales/${locale}.json`)
            i18n.global.setLocaleMessage(locale, messages.default)
        }

        return nextTick()
    },
}
export default Translation
