<script>
import Translation from "@/i18n/translation"

export default {
  components: {},
  data: () => {
    return {};
  },
  setup() {
    return { Translation }
  }
};
</script>

<template>
  <RouterLink class="text-base md:text-2xl md:font-bold text-gray-700 hover:text-red-300 transition-colors duration-200" :to="Translation.i18nRoute({ name: Translation.currentLocale + '.contact' })" :title='$t("nav.contact")'>{{ $t("nav.contact") }}</RouterLink>
</template>
