<script>
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'
import Translation from "@/i18n/translation"
import Overlay from "@/components/overlay/Overlay.vue";
import {mapState} from "vuex";
import {computed, watch} from 'vue';
import {assets} from "@/components/Assets.vue"
import {useI18n} from 'vue-i18n';

export default {
  components: {
    Header,
    Footer,
    Overlay,
  },
  data: () => {
    return {
      jsonData: null,
      numbers: null,
      gerechtFields: ['542748', '542752', '542753'],
      produktivFields: ['542749', '542750', '542751'],
      gruenFields: ['542754', '542755', '542756'],
      showScrollTop: false
    };
  },
  computed: {
    ...mapState({
      openOverlay: 'openOverlay',
      overlayData: 'overlayData',
    })
  },
  provide() {
    return {
      jsonData: computed(() => this.jsonData)
    }
  },
  mounted() {
    const offset = 500;

    window.addEventListener('scroll', () => {
      const scrollTop = window.scrollY;
      if (scrollTop >= offset) {
        this.showScrollTop = true;
      }
      else {
        this.showScrollTop = false;
      }
    }, {passive: true});
  },
  created() {
    this.fetchData();
    const {t, locale} = useI18n();

    watch(locale, () => {
      this.setDimensions();
    });
  },
  setup() {
    return {Translation}
  },
  methods: {
    async fetchData() {
      let url = import.meta.env.VITE_PROD_API_URL;
      let options = {priority: "low"};

      // if(import.meta.env.DEV) {
      //   url = import.meta.env.VITE_DEV_API_URL;
      //   options.headers = new Headers({
      //     'Authorization': 'Basic ' + encode("regensburg" + ":" + "regensburg"),
      //     'Content-Type': 'application/json'
      //   });
      // }

      let data = await (await fetch('/dashboard.json', options)).json()

      if (data) {
        await Object.keys(data.Kennzahlen).forEach(key => {
          let obj = data.Kennzahlen[key];
          let dataKey = obj.key.trim();
          let unit = obj.unit;
          obj.years = [];

          if (dataKey) {
            Object.keys(data.daten).forEach(key => {
              let dataObj = data.daten[key];
              let value = dataObj[dataKey];
              let datetime = dataObj.datetime;
              let year = new Date(datetime.replace(" ", "T")).getFullYear();

              if (value && value !== '00') {
                obj.years.push(
                    {
                      "title": year,
                      "value": value
                    }
                )
              }
            });
          }
        });

        data.kacheln = Object.entries(data.kacheln).sort((a, b) => a[1].sortby - b[1].sortby);

        await Object.keys(data.handlungsfelder).forEach(key => {
          let obj = data.handlungsfelder[key];
          obj.icon = '';
          obj.key = key;

          switch (key) {
            case "542748":
              obj.icon = assets.hf_partizipation_teilhabe;
              obj.dimension = 1;
              obj.sorting = 5;
              break;
            case "542749":
              obj.icon = assets.hf_digitalisierung;
              obj.dimension = 2;
              obj.sorting = 9;
              break;
            case "542750":
              obj.icon = assets.hf_wissenschaft;
              obj.dimension = 2;
              obj.sorting = 8;
              break;
            case "542751":
              obj.icon = assets.hf_arbeit_wirtschaft;
              obj.dimension = 2;
              obj.sorting = 7;
              break;
            case "542752":
              obj.icon = assets.hf_globale_verantwortung;
              obj.dimension = 1;
              obj.sorting = 6;
              break;
            case "542753":
              obj.icon = assets.hf_leben_wohnen;
              obj.dimension = 1;
              obj.sorting = 4;
              break;
            case "542754":
              obj.icon = assets.hf_mobilitaet_infrastruktur;
              obj.dimension = 0;
              obj.sorting = 2;
              break;
            case "542755":
              obj.icon = assets.hf_umwelt_ressourcenschutz;
              obj.dimension = 0;
              obj.sorting = 3;
              break;
            case "542756":
              obj.icon = assets.hf_klimaschutz_energie;
              obj.dimension = 0;
              obj.sorting = 1;
              break;
            default:
              break;
          }
        });

        data.handlungsfelder = Object.entries(data.handlungsfelder).sort((a, b) => a[1].sorting - b[1].sorting);

        await Object.keys(data.sdg).forEach(key => {
          let obj = data.sdg[key];
          obj.icon = '';

          let title = obj.title;
          switch (title) {
            case "1":
              obj.icon = assets.sdg_1;
              obj.icon_en = assets.sdg_1_en;
              break;
            case "2":
              obj.icon = assets.sdg_2;
              obj.icon_en = assets.sdg_2_en;
              break;
            case "3":
              obj.icon = assets.sdg_3;
              obj.icon_en = assets.sdg_3_en;
              break;
            case "4":
              obj.icon = assets.sdg_4;
              obj.icon_en = assets.sdg_4_en;
              break;
            case "5":
              obj.icon = assets.sdg_5;
              obj.icon_en = assets.sdg_5_en;
              break;
            case "6":
              obj.icon = assets.sdg_6;
              obj.icon_en = assets.sdg_6_en;
              break;
            case "7":
              obj.icon = assets.sdg_7;
              obj.icon_en = assets.sdg_7_en;
              break;
            case "8":
              obj.icon = assets.sdg_8;
              obj.icon_en = assets.sdg_8_en;
              break;
            case "9":
              obj.icon = assets.sdg_9;
              obj.icon_en = assets.sdg_9_en;
              break;
            case "10":
              obj.icon = assets.sdg_10;
              obj.icon_en = assets.sdg_10_en;
              break;
            case "11":
              obj.icon = assets.sdg_11;
              obj.icon_en = assets.sdg_11_en;
              break;
            case "12":
              obj.icon = assets.sdg_12;
              obj.icon_en = assets.sdg_12_en;
              break;
            case "13":
              obj.icon = assets.sdg_13;
              obj.icon_en = assets.sdg_13_en;
              break;
            case "14":
              obj.icon = assets.sdg_14;
              obj.icon_en = assets.sdg_14_en;
              break;
            case "15":
              obj.icon = assets.sdg_15;
              obj.icon_en = assets.sdg_15_en;
              break;
            case "16":
              obj.icon = assets.sdg_16;
              obj.icon_en = assets.sdg_16_en;
              break;
            case "17":
              obj.icon = assets.sdg_17;
              obj.icon_en = assets.sdg_17_en;
              break;
            default:
              break;
          }
        });

        data.sdg = Object.entries(data.sdg).sort((a, b) => a[1].title - b[1].title)

        this.jsonData = data;

        this.setDimensions();
      }
    },
    setDimensions() {
      if (this.jsonData) {
        this.jsonData.dimensions = {
          "grün": {
            "fields": this.gruenFields,
            "icon": assets.hd_gruen,
            "title": this.$t('dimension.green')
          },
          "gerecht": {
            "fields": this.gerechtFields,
            "icon": assets.hd_gerecht,
            "title": this.$t('dimension.just')
          },
          "produktiv": {
            "fields": this.produktivFields,
            "icon": assets.hd_produktiv,
            "title": this.$t('dimension.productive')
          },
        }
      }
    },
    scrollToTop() {
      document.getElementById('app').scrollIntoView({behavior: 'smooth'});
    },
    hasScrolled() {
      if (document.getElementById('app').scrollTop > 20 || document.getElementById('app').scrollTop > 20) {
        return true;
      } else {
        return false;
      }
    }

  }
};

</script>

<template>
  <router-view/>

  <Overlay v-if="!$route.matched.some(r => r.name === 'embed')" @close="openOverlay = false"></Overlay>

  <div class="fixed w-full bottom-0">
    <div class="container relative">
      <img v-show="showScrollTop" @click="scrollToTop()" :alt="$t('general.totop')"
           class="cursor-pointer absolute bottom-5 sm:bottom-10 right-5 sm:right-10 xl:-right-10 rounded-full shadow-arrow hover:shadow-info transition-shadow duration-200"
           src="@/assets/images/arrow-top.svg" width="36" height="36" loading="lazy"/>
    </div>
  </div>
</template>
