<script>
import {mapState} from "vuex";
import Flicking from "@egjs/vue3-flicking";
import {Arrow} from "@egjs/flicking-plugins";
import "@egjs/vue3-flicking/dist/flicking.css";
import "@egjs/flicking-plugins/dist/arrow.css";
import {Pagination} from "@egjs/flicking-plugins";
import "@egjs/flicking-plugins/dist/pagination.css";

export default {
  components: {
    Flicking: Flicking
  },
  computed: {
    ...mapState({
      overlayData: 'overlayData',
    })
  },
  data: () => {
    return {
      plugins: [
        new Arrow({parentEl: document.body,prevElSelector: ".flicking-arrow-prev-overlay", nextElSelector: ".flicking-arrow-next-overlay"}),
        new Pagination({type: 'bullet'})
      ],
      panelsPerView: 2,
      circular: false,
      align: 'prev',
      slides: [],
      windowWidth: window.innerWidth,
      imageHeight: 300,
    };
  },
  created() {
    if (this.overlayData.slidertext1 || this.overlayData.slidertitle1 || this.overlayData.sliderimage1 || this.overlayData.sliderlink1) {
      this.slides.push({title: this.overlayData.slidertitle1, text: this.overlayData.slidertext1, image: this.overlayData.sliderimage1, link: this.overlayData.sliderlink1, extension:  this.overlayData.sliderimage1extension, copyright: this.overlayData.sliderimage1copyright});
    }
    if (this.overlayData.slidertext2 || this.overlayData.slidertitle2 || this.overlayData.sliderimage2 || this.overlayData.sliderlink2) {
      this.slides.push({title: this.overlayData.slidertitle2, text: this.overlayData.slidertext2, image: this.overlayData.sliderimage2, link: this.overlayData.sliderlink2, extension:  this.overlayData.sliderimage2extension, copyright: this.overlayData.sliderimage2copyright});
    }
    if (this.overlayData.slidertext3 || this.overlayData.slidertitle3 || this.overlayData.sliderimage3 || this.overlayData.sliderlink3) {
      this.slides.push({title: this.overlayData.slidertitle3, text: this.overlayData.slidertext3, image: this.overlayData.sliderimage3, link: this.overlayData.sliderlink3, extension:  this.overlayData.sliderimage3extension, copyright: this.overlayData.sliderimage3copyright});
    }
    if (this.overlayData.slidertext4 || this.overlayData.slidertitle4 || this.overlayData.sliderimage4 || this.overlayData.sliderlink4) {
      this.slides.push({title: this.overlayData.slidertitle4, text: this.overlayData.slidertext4, image: this.overlayData.sliderimage4, link: this.overlayData.sliderlink4, extension:  this.overlayData.sliderimage4extension, copyright: this.overlayData.sliderimage4copyright});
    }
    if (this.overlayData.slidertext5 || this.overlayData.slidertitle5 || this.overlayData.sliderimage5 || this.overlayData.sliderlink5) {
      this.slides.push({title: this.overlayData.slidertitle5, text: this.overlayData.slidertext5, image: this.overlayData.sliderimage5, link: this.overlayData.sliderlink5, extension:  this.overlayData.sliderimage5extension, copyright: this.overlayData.sliderimage5copyright});
    }

    this.setPanelsPerView();
    this.setCircularAndAlign();

    this.$watch('windowWidth', (value) => {
      this.setPanelsPerView();
      this.setCircularAndAlign();
    })
  },
  mounted() {
    this.$nextTick(() => {
      this.setImageHeight();

      window.addEventListener('resize', this.onResize);
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    setImageHeight() {
      const image = document.querySelector('.slide-image');
      if (image) {
        if(image.offsetHeight !== 0) {
          this.imageHeight = image.offsetHeight;
        }
      }
    },
    setPanelsPerView() {
      if (this.windowWidth >= 768) {
        this.panelsPerView = 2;
      } else {
        this.panelsPerView = 1;
      }
    },
    setCircularAndAlign() {
      if (this.windowWidth >= 768) {
        this.circular = false;
        this.align = 'prev';
      } else {
        this.circular = false;
        this.align = 'prev';
      }
    },
    onResize() {
      this.windowWidth = window.innerWidth;
      this.setImageHeight();
    }
  }
}
</script>

<template>
  <div class="px-6 xl:px-19">
    <div class="overflow-visible relative">
      <Flicking ref="fields-flicking" class="-mx-5 px-5 -mt-5 pt-5" :class="[slides.length > 1 ? 'pb-10' : '-mb-5 pb-5']" :plugins="plugins" :options="{
        align,
        defaultIndex: 0,
        circular,
        circularFallback: 'bound',
        moveType: 'snap',
        panelsPerView,
        bound: true
      }">

        <template v-if="slides" v-for="(slide, index) in slides" :key="index">
          <div class="block hyphens-auto mr-10 min-h-full">
            <div class="shadow-card h-full flex flex-col">
              <div class="relative h-fit" v-if="slide.image">
                <picture >
                  <source
                      :srcset="slide.image + '-499px.' + slide.extension"
                      media="(min-width: 1520px)">
                  <source
                      :srcset="slide.image + '-375px.' + slide.extension"
                      media="(min-width: 1280px)">
                  <source
                      :srcset="slide.image + '-353px.' + slide.extension"
                      media="(min-width: 1024px)">
                  <source
                      :srcset="slide.image + '-468px.' + slide.extension"
                      media="(min-width: 768px)">
                  <source
                      :srcset="slide.image + '-690px.' + slide.extension"
                      media="(min-width: 640px)">
                  <source
                      :srcset="slide.image + '-592px.' + slide.extension"
                      media="(min-width: 390px)">
                  <img :src="slide.image + '-342px.' + slide.extension" alt=""
                       class="aspect-[3/2] w-full object-cover slide-image">
                </picture>
                <div v-if="slide.copyright"
                     class="absolute bg-gray-200 text-xs py-1 px-2 text-grey-500 bottom-0 left-0 z-[10] max-w-full hover:max-h-[300px] line-clamp-1 hover:line-clamp-none max-h-6 transition-[max-height] duration-300">
                  {{$t('general.copyright')}}{{slide.copyright}}
                </div>
              </div>

              <div v-if="!slide.image" class="bg-gray-200 pt-[66.66%]"></div>

              <div class="bg-white px-4 pt-5 pb-6 flex flex-col justify-between h-full">
                <div>
                  <p v-if="slide.title" class="text-xl font-bold mb-2">{{ slide.title }}</p>
                  <p v-if="slide.text">{{ slide.text }}</p>
                </div>

                <a v-if="slide.link" :href="slide.link" target="_blank" class="self-start	inline-block bg-red-300 p-2 text-white mt-6 hover:shadow-info transition-shadow duration-200">{{ $t('overlay.slider.learn_more') }}</a>
              </div>
            </div>
          </div>
        </template>

        <template #viewport>
          <div v-show="slides.length > 1" class="flicking-pagination"></div>
        </template>
      </Flicking>

      <span v-show="slides.length > 1" class="flicking-arrow-prev flicking-arrow-prev-overlay" :style="{ top: imageHeight / 2 + 'px !important' }"></span>
      <span v-show="slides.length > 1" class="flicking-arrow-next flicking-arrow-next-overlay" :style="{ top: imageHeight / 2 + 'px !important' }"></span>
    </div>
  </div>

</template>

<style>
.flicking-arrow-prev, .flicking-arrow-next {
  height: 36px;
  width: 36px!important;
  border-radius: 50%;
  box-shadow: 0px 1px 10px #707070;
  transform: none;
}

.flicking-arrow-prev {
  left: -18px;
  background-image: url("@/assets/images/arrow-left.svg");
}
.flicking-arrow-next {
  right: -18px;
  background-image: url("@/assets/images/arrow-right.svg");
}

.flicking-arrow-prev::before, .flicking-arrow-prev::after {
  display: none;
  right: 0;
  left: unset;
}

.flicking-arrow-next::before, .flicking-arrow-next::after {
  left: 0;
  right: unset;
}

.flicking-arrow-prev:hover, .flicking-arrow-next:hover {
  box-shadow: 0px 1px 10px #707070;
}

.flicking-arrow-prev {
  background-image: url("@/assets/images/arrow-left.svg");
}
.flicking-arrow-next {
  background-image: url("@/assets/images/arrow-right.svg");
}

.flicking-arrow-prev.flicking-arrow-disabled {
  background-image: url("@/assets/images/arrow-left-inactive.svg");
}
.flicking-arrow-next.flicking-arrow-disabled {
  background-image: url("@/assets/images/arrow-right-inactive.svg");
}


.flicking-pagination-bullet-active {
  background-color: #E30613!important;
}

.flicking-pagination-scroll {
  width: auto!important;
}

/*.flicking-viewport:before {*/
/*  content: "";*/
/*  display: block;*/
/*  position: absolute;*/
/*  height: 100%;*/
/*  left: 0;*/
/*  width: 50px;*/
/*  background-color: white;*/
/*}*/
</style>
