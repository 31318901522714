<script>
import {computed} from "vue";
import {mapState} from "vuex";

import {defineAsyncComponent} from 'vue'

const Dimensions = defineAsyncComponent(() =>
    import('@/components/filter/Dimensions.vue')
)
const Fields = defineAsyncComponent(() =>
    import('@/components/filter/Fields.vue')
)
const SDG = defineAsyncComponent(() =>
    import('@/components/filter/SDG.vue')
)

export default {
  components: {
    Dimensions,
    Fields,
    SDG
  },
  inject: ['jsonData'],
  data: () => {
    return {
      level1Filters: [
        'dimensions',
        'fields',
        'sdg'
      ],
      windowWidth: window.innerWidth,
    };
  },
  provide() {
    return {
      windowWidth: computed(() => this.windowWidth)
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  computed: {
    ...mapState({
      level1Filter: 'level1Filter',
    })
  },
  methods: {
    changeLevel1Filter(filter) {
      // Reset level 2 filter
      this.$store.commit('changeLevel2Filter', {title: null, key: null});

      // Change level 1 filter
      this.$store.commit('changeLevel1Filter', filter);
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    }
  }
}
</script>

<template>
  <div class="container">
    <div class="flex flex-col xl:flex-row">
      <h2 class="text-3xl text-black font-bold mb-6 lg:mb-12 hyphens-auto order-2 xl:order-1">{{ $t("filter.header") }}</h2>

      <div class="xl:ml-auto order-1 xl:order-2">
        <p class="text-base text-gray-400 mb-2.5">{{ $t("intro.download_text") }}</p>
        <a href="https://zukunft.regensburg.de/Download_Zukunftsbarometer_Regensburg.xlsx" title="Download Zukunftsbarometer Regensburg" download class="flex flex-row gap-2 mb-10 group flex-wrap hyphens-auto"
           onmousedown="_etracker.sendEvent(new et_DownloadEvent('Download_Zukunftsbarometer_Regensburg.xlsx'));"
        >
          <svg id="ic_download" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g id="Gruppe_4164" data-name="Gruppe 4164" transform="translate(1 0)">
              <path id="Pfad_5909" data-name="Pfad 5909" d="M20.275,74v3.77H1.752V74H0v5.523H22.027V74Z" transform="translate(0 -55.523)" fill="#e30613"/>
              <path id="Pfad_5910" data-name="Pfad 5910" d="M19.614,0V15.249l-6.4-6.4-1.239,1.239L20.49,18.6l8.517-8.517L27.768,8.847l-6.4,6.4V0Z" transform="translate(-8.976)" fill="#e30613"/>
            </g>
            <rect id="Rechteck_134" data-name="Rechteck 134" width="24" height="24" transform="translate(0 0)" fill="none"/>
          </svg>
          <span class="text-black group-hover:text-red-300 duration-200 transition-colors">Download_Zukunftsbarometer_Regensburg</span>
          <span class="text-gray-400 group-hover:text-red-300 duration-200 transition-colors">xlsx | 57 KB</span>
        </a>
      </div>
    </div>
    <div class="flex flex-wrap mb-15">
      <button @click="changeLevel1Filter(filter)" v-for="(filter, key, index) in level1Filters"
              :class="{ 'bg-red-300 text-white border-red-300': level1Filter === filter }"
              class="flex basis-full md:basis-1/3	justify-center items-center h-16 text-gray-700 text-xl	lg:text-2xl border border-gray-700 hover:bg-red-300 hover:text-white transition-colors hover:border-red-300">
        {{ $t("filter." + filter) }}
      </button>
    </div>

    <div class="-mx-[30px] sm:mx-0 min-h-[244px]">
      <Dimensions v-if="level1Filter === 'dimensions'"></Dimensions>

      <Fields v-if="level1Filter === 'fields'"></Fields>

      <SDG v-if="level1Filter === 'sdg'"></SDG>
    </div>

  </div>
</template>

<style>
.flicking-arrow-prev, .flicking-arrow-next {
  top: calc(50% - 18px);
  height: 36px;
  width: 36px;
  border-radius: 50%;
  box-shadow: 0px 3px 6px #00000029;
  transition: box-shadow 0.2s;
}

.flicking-arrow-prev:hover, .flicking-arrow-next:hover {
  box-shadow: 0px 1px 10px #707070;
}

.flicking-arrow-prev {
  background-image: url("@/assets/images/arrow-left.svg");
}
.flicking-arrow-next {
  background-image: url("@/assets/images/arrow-right.svg");
}

.flicking-arrow-prev.flicking-arrow-disabled {
  background-image: url("@/assets/images/arrow-left-inactive.svg");
}
.flicking-arrow-next.flicking-arrow-disabled {
  background-image: url("@/assets/images/arrow-right-inactive.svg");
}

.flicking-arrow-disabled:hover {
  box-shadow: 0px 3px 6px #00000029;
}

.flicking-arrow-prev::before, .flicking-arrow-prev::after {
  display: none;
  right: 0;
  left: unset;
}

.flicking-arrow-next::before, .flicking-arrow-next::after {
  display: none;
  left: 0;
  right: unset;
}

.flicking-arrow-prev::before, .flicking-arrow-prev::after, .flicking-arrow-next::before, .flicking-arrow-next::after {
  width: 100%;
  background-color: #E30613!important;
}

.flicking-pagination-bullet-active {
  background-color: #E30613!important;
}

.flicking-pagination-scroll {
  width: auto!important;
}
</style>
