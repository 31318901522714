<script>
import {useI18n} from 'vue-i18n'
import { useRouter } from "vue-router"
import Translation from "@/i18n/translation"

export default {
  setup() {
    const {t, locale} = useI18n()

    const supportedLocales = Translation.supportedLocales

    const router = useRouter()

    const switchLanguage = async (event = null, lang = null) => {
      let newLocale = event.target.value

      if(lang) {
        newLocale = lang;
      }
      await Translation.switchLanguage(newLocale)

      try {
        let path = router.currentRoute.value.path;

        if(newLocale === 'en') {
          if(router.currentRoute.value.name === 'de.contact') {
            path = '/en/contact';
          }
          if(router.currentRoute.value.name === 'de.imprint') {
            path = '/en/imprint';
          }
          if(router.currentRoute.value.name === 'de.privacy') {
            path = '/en/privacy';
          }
          if(path === '/') {
            path = '/en'
          }

          await router.replace({ params: { locale: newLocale}, path: path })
        }
        else {
          if(router.currentRoute.value.name === 'en.contact') {
            path = '/kontakt';
          }
          if(router.currentRoute.value.name === 'en.imprint') {
            path = '/impressum';
          }
          if(router.currentRoute.value.name === 'en.privacy') {
            path = '/datenschutz';
          }

          if(path === '/en') {
            path = '/'
          }

          await router.replace({ params: { locale: ''}, path: path })
        }
      } catch(e) {
        router.push("/")
      }
    }

    return { t, locale, supportedLocales, switchLanguage }
  }
}
</script>

<template>
  <select class="cursor-pointer text-base md:text-2xl md:font-bold relative bg-transparent md:hidden" @change="switchLanguage">
    <option
        v-for="sLocale in supportedLocales"
        :key="`locale-${sLocale}`"
        :value="sLocale"
        :selected="locale === sLocale"
    >
      {{ t(`locale.${sLocale}`) }}
    </option>
  </select>

  <div class="text-base md:text-2xl md:font-bold relative hidden md:block">
    <button :title="sLocale.toUpperCase()" @click="switchLanguage($event, sLocale)" v-for="(sLocale, index) in supportedLocales" class="hover:text-gray-700 transition-colors duration-200" :class="{ 'text-gray-700': locale === sLocale, 'text-gray-250': locale !== sLocale }">
      {{ sLocale.toUpperCase() }}<span v-if="index !== supportedLocales.length - 1">&nbsp;/&nbsp;</span>
    </button>
  </div>
</template>
