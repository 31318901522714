<script>
import {mapState} from "vuex";
import Translation from "@/i18n/translation";

export default {
  computed: {
    ...mapState({
      overlayData: 'overlayData',
    })
  },
  mounted() {
    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
      anchor.addEventListener('click', function (e) {
        e.preventDefault();

        document.querySelector(this.getAttribute('href')).scrollIntoView({
          behavior: 'smooth'
        });
      });
    });
  },
  methods: {
    toggleOverlay() {
      this.$store.commit('toggleOverlay')
    },
    getIcon(field) {
      if(Translation.currentLocale === 'en') {
        return field.icon_en;
      }
      else {
        return field.icon;
      }
    }
  }
}
</script>

<template>
  <div class="px-6 xl:px-20 py-8 shadow-header flex flex-col gap-6 bg-white">
    <div class="flex flex-row justify-between space-x-4">

      <div class="text-black font-bold text-4xl md:text-5xl hyphens-auto min-w-0">{{ overlayData.header }}</div>
      <button class="h-9 rounded-full flex justify-center shrink-0 hover:shadow-info-close transition-shadow duration-200" @click="toggleOverlay">
        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
          <g>
            <g fill="none" stroke="#191919" stroke-width="2">
              <circle cx="18" cy="18" r="18" stroke="none"/>
              <circle cx="18" cy="18" r="17" fill="none"/>
            </g>
            <g transform="translate(10 10)">
              <path id="Pfad_247" data-name="Pfad 247" d="M6396.07-5129.94l16-16" transform="translate(-6396.07 5145.938)" fill="none" stroke="#191919" stroke-width="2"/>
              <path id="Pfad_248" data-name="Pfad 248" d="M0,16,16,0" transform="translate(16) rotate(90)" fill="none" stroke="#191919" stroke-width="2"/>
            </g>
          </g>
        </svg>
      </button>
    </div>
    <div class="flex flex-col xl:flex-row xl:justify-between xl:items-center gap-6">
      <div class="flex flex-col gap-[10px]">
        <a v-if="overlayData.fliptext1 || overlayData.flipimage1" href="#section-achievement" class="h-[26px] text-white bg-red-300 flex flex-row w-fit">
          <span class="w-[26px] block bg-red-600 justify-center items-center flex">
            <svg xmlns="http://www.w3.org/2000/svg" width="15.874" height="10.144" viewBox="0 0 15.874 10.144">
              <g id="Gruppe_231" data-name="Gruppe 231" transform="translate(15.419 0.376) rotate(90)">
                <g id="Gruppe_399" data-name="Gruppe 399" transform="translate(0.678 0.612)">
                  <path id="Pfad_167" data-name="Pfad 167" d="M1.061,1.061l6.955,6.87L1.061,14.8" transform="translate(-1.061 -1.061)" fill="none" stroke="#fff" stroke-width="3"/>
                </g>
              </g>
            </svg>
          </span>
          <span class="px-2 py-0.5">{{ $t("overlay.background") }}</span>
        </a>
        <a v-if="overlayData.slidertext1 || overlayData.slidertext2 || overlayData.slidertext3 || overlayData.slidertext4 || overlayData.slidertext5 || overlayData.slidertitle1 || overlayData.slidertitle2 || overlayData.slidertitle3 || overlayData.slidertitle4 || overlayData.slidertitle5 || overlayData.sliderimage1 || overlayData.sliderimage2 || overlayData.sliderimage3 || overlayData.sliderimage4 || overlayData.sliderimage5" href="#section-engagement" class="h-[26px] text-white bg-red-300 flex flex-row w-fit">
          <span class="w-[26px] block bg-red-600 justify-center items-center flex">
            <svg xmlns="http://www.w3.org/2000/svg" width="15.874" height="10.144" viewBox="0 0 15.874 10.144">
              <g id="Gruppe_231" data-name="Gruppe 231" transform="translate(15.419 0.376) rotate(90)">
                <g id="Gruppe_399" data-name="Gruppe 399" transform="translate(0.678 0.612)">
                  <path id="Pfad_167" data-name="Pfad 167" d="M1.061,1.061l6.955,6.87L1.061,14.8" transform="translate(-1.061 -1.061)" fill="none" stroke="#fff" stroke-width="3"/>
                </g>
              </g>
            </svg>
          </span>
          <span class="px-2 py-0.5">{{ $t("overlay.engagement") }}</span>
        </a>
        <a v-if="overlayData.fliptext3 || overlayData.flipimage3" href="#section-contribution" class="h-[26px] text-white bg-red-300 flex flex-row w-fit">
          <span class="w-[26px] block bg-red-600 justify-center items-center flex">
            <svg xmlns="http://www.w3.org/2000/svg" width="15.874" height="10.144" viewBox="0 0 15.874 10.144">
              <g id="Gruppe_231" data-name="Gruppe 231" transform="translate(15.419 0.376) rotate(90)">
                <g id="Gruppe_399" data-name="Gruppe 399" transform="translate(0.678 0.612)">
                  <path id="Pfad_167" data-name="Pfad 167" d="M1.061,1.061l6.955,6.87L1.061,14.8" transform="translate(-1.061 -1.061)" fill="none" stroke="#fff" stroke-width="3"/>
                </g>
              </g>
            </svg>
          </span>
          <span class="px-2 py-0.5">{{ $t("overlay.contribution") }}</span>
        </a>
      </div>
      <div class="flex flex-row gap-4">
        <template v-for="(sdg, key, index) in overlayData.sdg">
          <img :alt="sdg.title" :src="getIcon(sdg)" width="100"/>
        </template>
      </div>

    </div>

  </div>
</template>
