<script>
import Header from "@/components/overlay/parts/Header.vue"
import Content from "@/components/overlay/parts/Content.vue"
import Download from "@/components/overlay/parts/Download.vue"
import Iframe from "@/components/overlay/parts/Iframe.vue"
import {mapState} from "vuex";

export default {
  components: {
    Header,
    Content,
    Download,
    Iframe
  },
  data: () => {
    return {
      showScrollTop: false
    }
  },
  computed: {
    ...mapState({
      openOverlay: 'openOverlay',
      overlayData: 'overlayData',
    })
  },
  methods: {
    closeOverlay(e) {
      if (this.openOverlay && !e.target.classList.contains('to-top')) {
        this.$store.commit('toggleOverlay')
      }
    },
    scrollToTop() {
      this.$refs.sidebarcontainer.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    },
    onScroll(e) {
      const offset = 100;

      const target = e.target;
      const scrollTop = target.scrollTop;

      if (scrollTop >= offset) {
        this.showScrollTop = true;
      } else {
        this.showScrollTop = false;
      }
    },
  },
  watch: {
    openOverlay(open) {
      if (open) {
        this.$refs.sidebar.focus();
      }
    },
    mounted() {
      this.$refs.sidebar.addEventListener('scroll', this.onScroll, {passive: true});
    },
    beforeDestroy() {
      this.$refs.sidebar.removeEventListener('scroll', this.onScroll);
    },
  },
}
</script>

<template>
  <aside @keydown.esc="closeOverlay" ref="sidebar" tabindex="0" id="default-sidebar"
         class="fixed z-40 w-full top-0 left-0 h-dvh bg-[rgba(0,0,0,.4)] transition-all"
         :class="{'opacity-0 !-z-[1]': !openOverlay}" aria-label="Sidebar">
    <div @scroll.passive="onScroll" ref="sidebarcontainer"
         class="h-full overflow-y-auto bg-white w-full lg:w-[62%] duration-150 lg:max-w-[1192px] transition-transform top-0 right-0 absolute overscroll-contain"
         :class="[!openOverlay ? 'translate-x-full' : 'translate-x-0']">
      <div class="h-full" :class="{'bg-gray-200': overlayData.cardData}" v-if="overlayData && openOverlay"
           v-click-away="closeOverlay">
        <Header></Header>
        <Content v-if="!overlayData.cardData"></Content>
        <Iframe v-if="overlayData.cardData"></Iframe>
        <Download v-if="!overlayData.cardData"></Download>
      </div>
    </div>
  </aside>

  <img v-show="showScrollTop" @click="scrollToTop()" :alt="$t('general.totop')"
       class="to-top z-50 cursor-pointer fixed bottom-5 sm:bottom-10 right-5 sm:right-10 rounded-full shadow-arrow hover:shadow-info transition-shadow duration-200"
       src="@/assets/images/arrow-top.svg" width="36" height="36" loading="lazy"/>
</template>

