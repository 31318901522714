<script>
export default {
  data() {
    return {
      hover: false,
    };
  }
}
</script>

<template>
  <div @mouseover="hover = true"
       @mouseleave="hover = false">
    <svg v-show="!hover" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="33"
         height="32" viewBox="0 0 33 32">
      <defs>
        <clipPath id="instagram-clip-path">
          <rect width="33" height="32" fill="#707070"/>
        </clipPath>
      </defs>
      <g transform="translate(0)" clip-path="url(#instagram-clip-path)">
        <path
            d="M9.415.112a11.759,11.759,0,0,0-3.9.755A7.858,7.858,0,0,0,2.674,2.725,7.863,7.863,0,0,0,.826,5.576a11.8,11.8,0,0,0-.739,3.9C.012,11.192-.006,11.739.006,16.1s.027,4.911.109,6.626a11.779,11.779,0,0,0,.755,3.9A7.868,7.868,0,0,0,2.728,29.47a7.891,7.891,0,0,0,2.85,1.848,11.788,11.788,0,0,0,3.9.739c1.714.076,2.261.092,6.624.08s4.912-.027,6.626-.108a11.8,11.8,0,0,0,3.9-.755,8.212,8.212,0,0,0,4.69-4.709,11.784,11.784,0,0,0,.739-3.9c.075-1.716.092-2.263.08-6.627s-.028-4.91-.109-6.624a11.788,11.788,0,0,0-.755-3.9,7.89,7.89,0,0,0-1.857-2.84A7.863,7.863,0,0,0,26.567.826a11.752,11.752,0,0,0-3.9-.739C20.952.011,20.4,0,16.04,0s-4.91.03-6.624.112M9.6,29.164a8.922,8.922,0,0,1-2.984-.546,5,5,0,0,1-1.848-1.2A4.991,4.991,0,0,1,3.565,25.57,8.9,8.9,0,0,1,3,22.587c-.08-1.694-.1-2.2-.106-6.493s.007-4.8.08-6.493a8.882,8.882,0,0,1,.546-2.984,4.989,4.989,0,0,1,1.2-1.848A4.981,4.981,0,0,1,6.57,3.563,8.9,8.9,0,0,1,9.553,3c1.695-.08,2.2-.1,6.492-.106s4.8.006,6.495.08a8.874,8.874,0,0,1,2.983.546,4.972,4.972,0,0,1,1.848,1.2,4.981,4.981,0,0,1,1.205,1.848,8.873,8.873,0,0,1,.558,2.981c.08,1.695.1,2.2.107,6.493s-.007,4.8-.08,6.493a8.9,8.9,0,0,1-.546,2.986,5.322,5.322,0,0,1-3.045,3.053,8.89,8.89,0,0,1-2.981.562c-1.695.08-2.2.1-6.494.106s-4.8-.008-6.493-.08M22.7,7.481a1.928,1.928,0,1,0,1.928-1.928A1.929,1.929,0,0,0,22.7,7.481M7.82,16.087A8.252,8.252,0,1,0,16.056,7.82h0A8.25,8.25,0,0,0,7.82,16.086v0m2.893-.006a5.357,5.357,0,1,1,5.368,5.347h0a5.356,5.356,0,0,1-5.367-5.347"
            transform="translate(0.616 -0.071)" fill="#707070"/>
      </g>
    </svg>
    <svg v-show="hover" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="33"
         height="32" viewBox="0 0 33 32">
      <defs>
        <radialGradient id="instagram-radial-gradient" cx="0.335" cy="0.846" r="0.968"
                        gradientTransform="translate(-0.533 1.12) rotate(-85.877)" gradientUnits="objectBoundingBox">
          <stop offset="0" stop-color="#feda75"/>
          <stop offset="0.219" stop-color="#fa7e1e"/>
          <stop offset="0.46" stop-color="#d62976"/>
          <stop offset="0.773" stop-color="#962fbf"/>
          <stop offset="1" stop-color="#4f5bd5"/>
        </radialGradient>
        <clipPath id="instagram-hover-clip-path">
          <rect width="33" height="32" fill="url(#instagram-radial-gradient)"/>
        </clipPath>
      </defs>
      <g clip-path="url(#instagram-hover-clip-path)">
        <path
            d="M9.415.112a11.759,11.759,0,0,0-3.9.755A7.858,7.858,0,0,0,2.674,2.725,7.863,7.863,0,0,0,.826,5.576a11.8,11.8,0,0,0-.739,3.9C.012,11.192-.006,11.739.006,16.1s.027,4.911.109,6.626a11.779,11.779,0,0,0,.755,3.9A7.868,7.868,0,0,0,2.728,29.47a7.891,7.891,0,0,0,2.85,1.848,11.788,11.788,0,0,0,3.9.739c1.714.076,2.261.092,6.624.08s4.912-.027,6.626-.108a11.8,11.8,0,0,0,3.9-.755,8.212,8.212,0,0,0,4.69-4.709,11.784,11.784,0,0,0,.739-3.9c.075-1.716.092-2.263.08-6.627s-.028-4.91-.109-6.624a11.788,11.788,0,0,0-.755-3.9,7.89,7.89,0,0,0-1.857-2.84A7.863,7.863,0,0,0,26.567.826a11.752,11.752,0,0,0-3.9-.739C20.952.011,20.4,0,16.04,0s-4.91.03-6.624.112M9.6,29.164a8.922,8.922,0,0,1-2.984-.546,5,5,0,0,1-1.848-1.2A4.991,4.991,0,0,1,3.565,25.57,8.9,8.9,0,0,1,3,22.587c-.08-1.694-.1-2.2-.106-6.493s.007-4.8.08-6.493a8.882,8.882,0,0,1,.546-2.984,4.989,4.989,0,0,1,1.2-1.848A4.981,4.981,0,0,1,6.57,3.563,8.9,8.9,0,0,1,9.553,3c1.695-.08,2.2-.1,6.492-.106s4.8.006,6.495.08a8.874,8.874,0,0,1,2.983.546,4.972,4.972,0,0,1,1.848,1.2,4.981,4.981,0,0,1,1.205,1.848,8.873,8.873,0,0,1,.558,2.981c.08,1.695.1,2.2.107,6.493s-.007,4.8-.08,6.493a8.9,8.9,0,0,1-.546,2.986,5.322,5.322,0,0,1-3.045,3.053,8.89,8.89,0,0,1-2.981.562c-1.695.08-2.2.1-6.494.106s-4.8-.008-6.493-.08M22.7,7.481a1.928,1.928,0,1,0,1.928-1.928A1.929,1.929,0,0,0,22.7,7.481M7.82,16.087A8.252,8.252,0,1,0,16.056,7.82h0A8.25,8.25,0,0,0,7.82,16.086v0m2.893-.006a5.357,5.357,0,1,1,5.368,5.347h0a5.356,5.356,0,0,1-5.367-5.347"
            transform="translate(0.616 -0.071)" fill="url(#instagram-radial-gradient)"/>
      </g>
    </svg>
  </div>
</template>
