{
  "locale": {
    "de": "Deutsch",
    "en": "Englisch"
  },
  "nav": {
    "home": "Home",
    "about": "Über uns",
    "contact": "Kontakt"
  },
  "intro": {
    "header": "Zukunftsbarometer Regensburg",
    "subheader": "Gemeinsam. Zukunft. Gestalten.",
    "text_1": "Gemeinsam mit Ihnen wollen wir die UNESCO Welterbe-Stadt Regensburg fit für die Zukunft machen. Unsere Stadt soll noch grüner, gerechter und produktiver und damit lebenswerter für alle werden.",
    "text_2": "Der Weg zu einer „enkelgerechten“ Stadt ist im Regensburg-Plan 2040 beschrieben und wurde im Einklang mit den 17 Nachhaltigkeitszielen (Sustainable Development Goals) mit den Bürgerinnen und Bürgern Regensburgs entwickelt. Nun gilt es, den Plan gemeinsam mit Leben zu füllen.",
    "text_3": "Nachhaltige Entwicklung ist ein fortlaufender Prozess. Das Zukunftsbarometer wird uns bei diesem Prozess begleiten und unsere Stärken und Schwächen sichtbar machen. Dadurch liefert es eine fundierte Basis für richtungsweisende Entscheidungen auf dem Weg hin zu einer nachhaltigeren Zukunft.",
    "download_text": "Alle Daten und Informationen zur Methodik sind hier als Download verfügbar.",
    "arrow_text": "Veränderung zum Vorjahr"
  },
  "filter": {
    "header": "Welcher Bereich interessiert Sie?",
    "dimensions": "Handlungsdimensionen",
    "fields": "Handlungsfelder",
    "sdg": "SDG Ziele"
  },
  "dimension": {
    "green": "Grün",
    "productive": "Produktiv",
    "just": "Gerecht"
  },
  "field": {
    "542756": "Klimaschutz und Energie",
    "542755": "Umwelt und Ressourcenschutz",
    "542754": "Mobilität und Infrastruktur",
    "542751": "Arbeit und Wirtschaft",
    "542750": "Wissenschaft",
    "542749": "Digitalisierung",
    "542748": "Partizipation und Teilhabe",
    "542753": "Leben und Wohnen",
    "542752": "Globale Verantwortung"
  },
  "about": {
    "header": "Über uns"
  },
  "contact": {
    "header": "Koordinator für kommunale Entwicklungspolitik",
    "phone": "Telefon:",
    "fax": "Fax:",
    "mail": "Email:"
  },
  "footer": {
    "partner": "Förderer und Partner",
    "imprint": "Impressum",
    "privacy": "Datenschutz"
  },
  "privacy": {
    "header": "Datenschutzerklärung",
    "subheader1": "Zuständiger behördlicher Datenschutzbeauftragter:",
    "subheader2": "Bayerisches Datenschutzgesetz",
    "subheader3": "Umgang mit personenbezogenen Daten",
    "subheader4": "Webserver",
    "subheader5": "Auswertung des Nutzerverhaltens (Webtracking)",
    "subheader6": "Nicht personenbezogene Daten",
    "subheader7": "Cookies",
    "subheader8": "Disclaimer",
    "subheader9": "Verpflichtung der städtischen Mitarbeiter auf den Datenschutz",
    "subheader10": "Rechte als Betroffener",
    "subheader11": "Kontakt für weiterführende Fragen",
    "text1": "Die Stadt Regensburg beachtet selbstverständlich die datenschutzrechtlichen Bestimmungen der EU-DSGVO und das Telemediengesetz. Verantwortlich für die Einhaltung der datenschutzrechtlichen Bestimmungen laut Datenschutz-Grundverordnung ist Oberbürgermeisterin Gertrud Maltz-Schwarzfischer.<br><br>Die Sicherheit Ihrer Daten ist uns wichtig, deshalb werden alle unsere Internetseiten mit einer Transportverschlüsselung (https) angeboten, damit die Daten nach dem aktuellen Stand der Technik sicher übertragen werden.",
    "text2": "Thomas Köckerbauer <br>Maximilianstr. 13<br>93047 Regensburg <br>E-Mail: <a href='mailto:datenschutz&commat;regensburg.de'>datenschutz&commat;regensburg.de</a><br>Telefon: <a href='tel:09415072114'>(0941) 507 - 2114</a><br>Fax: (0941) 507 - 4119",
    "text3": "Wir unterliegen als öffentliche Stelle den Bestimmungen der EU-Datenschutzgrundverordnung (EU-DSGVO) sowie des Bayerischen Datenschutzgesetzes (BayDSG). Zum Schutz Ihrer Rechte haben wir technische und organisatorische Maßnahmen getroffen und auch sichergestellt, dass die Vorschriften über den Datenschutz von externen Dienstleistern beachtet werden, die an diesem Angebot mitwirken. Ihre Daten dürfen nur in dem Umfange verarbeitet werden, wie spezielle Gesetze dies zulassen oder Ihre Einwilligung vorliegt.",
    "text4": "Personenbezogene Daten sind Informationen zu Ihrer Identität. Hierunter fallen z.B. Angaben wie Ihr tatsächlicher Name, Ihre Adresse oder Email Adresse.<br><br>Grundsätzlich steht diese Website allen Nutzern zur Verfügung, ohne dass persönliche Daten erhoben werden. <br><br>Wenn Sie eine E-Mail an eine Dienststelle der Stadt Regensburg senden, wird Ihre Mail-Adresse nur für die Korrespondenz mit Ihnen verwendet. Wir weisen Sie darauf hin, dass die Datenübertragung über das Internet ungesichert erfolgt und die übertragenen Daten somit von Unbefugten zur Kenntnis genommen oder auch verfälscht werden können. Die Stadt Regensburg setzt derzeit keine Verschlüsselungstechnik ein.<br><br>Aufgrund der datenschutzrechtlichen Bedenklichkeit setzt die Stadt Regensburg auf ihren Seiten keine Social-Plugins, wie z.B. den Facebook Like-Button, ein. Beim Besuch einer Seite des Internetauftritts werden damit keine Daten an Social Media Anbieter übertragen. Verbindungen zu Social-Media-Anbietern werden nur per einfachem Link auf die jeweilige Webseite, wie etwa die Fanseite <a href='https://www.facebook.com/regensburg.de' target='_blank'>www.facebook.com/regensburg.de</a> angeboten.<br><br>Die Nutzung des Like-Buttons oder entsprechender Funktionen auf den verlinkten Webseiten ist möglich. Hierbei sollten die Datenschutzhinweise und die Einstellungsmöglichkeiten zum Schutz der Privatsphäre des jeweiligen Social-Media-Anbieters beachtet werden.",
    "text5": "Der Webserver wird durch die nachstehende Firma betrieben:<br><br>Mittwald CM Service GmbH & Co. KG<br>Königsberger Straße 4-6<br>32339 Espelkamp<br><br>Die von Ihnen im Rahmen des Besuchs unseres Webauftritts übermittelten personenbezogenen Daten werden in unserem Auftrag verarbeitet.",
    "text6": "Die Stadt Regensburg verwendet Dienste der etracker GmbH aus Hamburg, Deutschland (<a href='www.etracker.com' target='_blank'>www.etracker.com</a>) zur Analyse von Nutzungsdaten. Es werden dabei anonymisierte Nutzerdaten verwendet. Die IP-Adressen der Nutzer werden verkürzt gespeichert und übermittelt. Damit werden keine personenbezogenen Daten übermittelt und es können keine personenbezogenen Auswertungen vorgenommen werden.<br><br>Es werden dabei keine Cookies eingesetzt.<br><br>Die mit etracker erzeugten Daten werden im Auftrag des Anbieters dieser Website von etracker ausschließlich in Deutschland verarbeitet und gespeichert und unterliegen damit den strengen deutschen und europäischen Datenschutzgesetzen und -standards. Etracker wurde diesbezüglich unabhängig geprüft, zertifiziert und mit dem Datenschutz-Gütesiegel ePrivacyseal ausgezeichnet.<br><br>Weitere Informationen zum Datenschutz bei etracker finden Sie <a href='https://www.etracker.com/datenschutz/' target='_blank'>hier.</a>",
    "text7": "Zum Zweck der technischen Bereitstellung der Webseite ist es erforderlich, bestimmte durch Ihren Browser übermittelte Informationen zu verarbeiten. Diese sind: Browsertyp und Browserversion, Datum und Uhrzeit des Zugriffs, IP Adresse, verwendetes Betriebssystem.<br><br>Die Access-Logs der Webserver protokollieren, welche Seitenaufrufe zu welchem Zeitpunkt stattgefunden haben. Sie beinhalten folgende Daten: <br>IP, Verzeichnisschutzbenutzer, Datum, Uhrzeit, aufgerufene Seiten, Protokolle, Statuscode, Datenmenge, Referer, User Agent, aufgerufener Hostname.<br><br>Die IP-Adressen werden dabei anonymisiert gespeichert. Hierzu werden die letzten ein bis drei Ziffern entfernt, d. h. aus „127.0.0.1“ wird „127.0.0.0“. IPv6-Adressen werden ebenfalls anonymisiert. Die anonymisierten IP-Adressen werden für 60 Tage aufbewahrt. Angaben zum verwendeten Verzeichnisschutzbenutzer werden nach einem Tag anonymisiert. <br><br>Error-Logs, welche fehlerhafte Seitenaufrufe protokollieren, werden nach sieben Tagen gelöscht. Diese beinhalten neben den Fehlermeldungen die zugreifende IP-Adresse und je nach Fehler die aufgerufene Webseite. <br><br>Die zuvor genannten Daten werden lediglich zur bedarfsgerechten Gestaltung und Optimierung unseres Internetangebotes sowie für statistische Zwecke verwendet. Die Daten erlauben keine Rückschlüsse auf Ihre Person. Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen, auch eine Auswertung der Daten zu Marketingzwecken findet nicht statt.",
    "text8": "Beim Besuch dieser Webseite werden keine Cookies gesetzt.",
    "text9": "Im Informationsangebot der Stadt Regensburg befinden sich Querverweise (\"Links\") zu anderen Internetangeboten. Durch den Querverweis vermittelt die Stadt Regensburg den Zugang zur Nutzung dieser Inhalte (§ 9 Teledienstegesetz). Für diese \"fremden\" Inhalte ist sie nicht verantwortlich, da sie die Übermittlung der Information nicht veranlasst, den Adressaten der übermittelten Informationen nicht auswählt und die übermittelten Informationen auch nicht ausgewählt oder verändert hat. Auch eine automatische kurzzeitige Zwischenspeicherung dieser \"fremden Informationen\" erfolgt wegen der gewählten Aufruf- und Verlinkungsmethodik durch die Stadt Regensburg nicht, so dass sich auch dadurch keine Verantwortlichkeit der Stadt Regensburg für diese fremden Inhalte ergibt. Bei der erstmaligen Verknüpfung mit diesen Internetangeboten hat die Stadt Regensburg den fremden Inhalt jedoch daraufhin überprüft, ob durch ihn eine mögliche zivilrechtliche oder strafrechtliche Verantwortlichkeit ausgelöst wird. Die Stadt Regensburg weist ausdrücklich darauf hin, dass auf verlinkten externen Homepages in der Regel eigene Datenschutzbestimmungen existieren, auf die die Stadt Regensburg keinen Einfluss hat. Jegliche Haftung und Verantwortung für die Datenschutzbestimmungen dieser Homepages ist somit ausgeschlossen. Einträge erfolgen unverbindlich und auf Widerruf. Es besteht kein Anspruch auf einen Eintrag.",
    "text10": "Die Mitarbeiterinnen und Mitarbeiter der Stadt Regensburg sind zur Verschwiegenheit und zur Einhaltung der maßgeblichen gesetzlichen Vorschriften des Datenschutzrechts verpflichtet.",
    "text11": "Nach der Datenschutz-Grundverordnung stehen Ihnen beim Verantwortlichen für die Datenerhebung folgende Rechte zu: Werden Ihre personenbezogenen Daten verarbeitet, so haben Sie das Recht Auskunft über die zu Ihrer Person gespeicherten Daten zu erhalten (Art. 15 DSGVO). Sollten unrichtige personenbezogene Daten verarbeitet werden, steht Ihnen ein Recht auf Berichtigung zu (Art. 16 DSGVO). Liegen die gesetzlichen Voraussetzungen vor, so können Sie die Löschung oder Einschränkung der Verarbeitung verlangen sowie Widerspruch gegen die Verarbeitung einlegen (Art. 17, 18 und 21 DSGVO). Wenn Sie in die Datenverarbeitung eingewilligt haben oder ein Vertrag zur Datenverarbeitung besteht und die Datenverarbeitung mithilfe automatisierter Verfahren durchgeführt wird, steht Ihnen gegebenenfalls ein Recht auf Datenübertragbarkeit zu (Art. 20 DSGVO). Sollten Sie von Ihren oben genannten Rechten Gebrauch machen, prüft die Stadt Regensburg, ob die gesetzlichen Voraussetzungen hierfür erfüllt sind. <br><br>Weiterhin besteht ein Beschwerderecht beim Bayerischen Landesbeauftragten für den Datenschutz:<br><br>Dr. Thomas Petri<br>Wagmüllerstraße 18 <br>80538 München <br>Telefon: <a href='tel:0892126720'>(089) 2 1 26 72 - 0</a> <br>Telefax: (089) 21 26 72 - 50 <br>E-Mail: <a href='mailto:poststelle&commat;datenschutz-bayern.de'>poststelle&commat;datenschutz-bayern.de</a><br>Internet: <a href='https://www.datenschutz-bayern.de' target='_blank'>www.datenschutz-bayern.de</a>",
    "text12": "Die inhaltliche Verantwortung für das von Ihnen aufgerufene Internetangebot der Stadt Regensburg wird im Impressum ausgewiesen. Wenn Sie Fragen haben, wenden Sie sich bitte an die dort ausgewiesene zuständige Person.<br><br>Datenschutzerklärung: Stand 9. Juni 2023"
  },
  "imprint": {
    "header": "Impressum",
    "subheader1": "Für allgemeine Fragen zur Stadtverwaltung wenden Sie sich bitte an:",
    "subheader2": "Herausgeber (gemäß § 5 TMG; § 55 II RStV):",
    "subheader3": "Inhaltlich verantwortlich (nach § 55 II RStV):",
    "subheader4": "Konzept, Web-Design, Programmierung:",
    "contact1": "E-Mail: <a href='mailto:stadt_regensburg&commat;regensburg.de'>stadt_regensburg&commat;regensburg.de</a><br>Telefon: (0941) 507-0<br>Fax: (0941) 507-1199<br><br><strong>Stadt Regensburg</strong><br><br>Postfach 11 06 43 <br>93019 Regensburg<br>",
    "contact2": "<strong>Stadt Regensburg</strong><br><br>Altes Rathaus<br>Rathausplatz 1 <br>93047 Regensburg<br><br>E-Mail: <a href='mailto:pressestelle&commat;regensburg.de'>pressestelle&commat;regensburg.de</a> <br>Internet: <a href='www.regensburg.de'>www.regensburg.de</a><br><br>Die Stadt Regensburg ist eine Gebietskörperschaft des Öffentlichen Rechts.<br>Sie wird vertreten durch Oberbürgermeisterin Gertrud Maltz-Schwarzfischer.<br><br>USt-Identifikationsnummer gemäß <br>§ 27 a UStG: DE 133714341<br>",
    "contact3": "<strong>Stadt Regensburg</strong><br><strong>Personal- und Verwaltungsreferat - Koordinator für kommunale Entwicklungspolitik</strong><br><br>Michael Grein <br><br>Altes Rathaus <br>Rathausplatz 1 <br>93047 Regensburg<br><br>Telefon: +49 (0) 941 507-1007<br>E-Mail: <a href='mailto:Grein.Michael&commat;Regensburg.de'>Grein.Michael&commat;Regensburg.de</a><br>",
    "contact4": "JANDA+ROSCHER, Die WerbeBotschafter<br><br>Roritzerstraße 10b<br>93047 Regensburg<br><br>Telefon: + 49 (0) 9 41 59 56 0-0<br>E-Mail: <a href='mailto:info&commat;janda-roscher.de'>info&commat;janda-roscher.de</a><br><br><a href='https://www.janda-roscher.de/'>https://www.janda-roscher.de/</a><br>"
  },
  "overlay": {
    "achievement": "Ziel",
    "background": "Hintergrund",
    "engagement": "Unser Engagement",
    "contribution": "Ihr Beitrag",
    "download": "Download",
    "iframe": {
      "header": "Diese Kachel auf Ihrer Website einbetten",
      "text1": "Sie möchten diese Kachel auf Ihrer Website darstellen?",
      "text2": "Nutzen Sie den iFrame Code und betten Sie diesen ganz einfach auf Ihrer Website ein.",
      "copy": "HTML-Code kopieren",
      "copied": "Code in Zwischenablage kopiert.",
      "privacy": "Durch das Einbetten dieser Inhalte stimmen Sie den <a class='font-bold hover:text-red-300 transition-colors duration-200' href='https://zukunft.regensburg.de/datenschutz' target='_blank'>Nutzungsbedingungen</a> des Zukunftsbarometer Regensburg zu."
    },
    "slider": {
      "learn_more": "Mehr erfahren"
    }
  },
  "slider": {
    "label": "Jahr auswählen"
  },
  "download": {
    "name": "Dateiname",
    "size": "Dateigröße",
    "format": "Dateiformat"
  },
  "paths": {
    "contact": "kontakt",
    "privacy": "datenschutz",
    "imprint": "impressum"
  },
  "general": {
    "totop": "Nach oben scrollen",
    "copyright": "Foto: "
  }
}
