<script>
import {mapState} from "vuex";
import '@vueup/vue-quill/dist/vue-quill.snow.css'
import Translation from "@/i18n/translation";


export default {

  computed: {
    ...mapState({
      overlayData: 'overlayData',
    })
  },
  data: () => {
    return {
      cardId: null,
      embedCode: '',
      codeCopied: false,
    }
  },
  created() {
    this.cardId = this.overlayData.cardData.id;

    if(Translation.currentLocale === 'en') {
        this.embedCode = '<iframe id="iframe-'+ this.cardId +'" src="https://zukunft.regensburg.de/en/embed/' + this.cardId + '" title="' + this.overlayData.cardData.title_en + '" width="363" style="border: none;"></iframe>';
    }
    else {
      this.embedCode = '<iframe id="iframe-'+ this.cardId +'" src="https://zukunft.regensburg.de/embed/' + this.cardId + '" title="' + this.overlayData.cardData.title + '" width="363" style="border: none;"></iframe>';
    }


    // this.setTextAreaRows();
    // this.$watch('windowWidth', (value) => {
    //   this.setTextAreaRows(value);
    // })
  },
  setup() {
    return { Translation }
  },
  mounted() {
    this.setTextAreaHeight();
    this.$nextTick(() => {
      window.addEventListener('resize', this.setTextAreaHeight);
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setTextAreaHeight);
  },
  methods: {
    copyCode() {
      const textarea = this.$refs.textarea;
      const value = textarea.value;

      if (value) {
        navigator.clipboard.writeText(value).then(
            () => {
              this.codeCopied = true;

              const that = this;
              setTimeout(() => {
                that.codeCopied = false;
              }, 1000);
            },
            () => {
              console.error('clipboard write failed');
            }
        );
      }
    },
    setTextAreaHeight() {
      let element = this.$refs.textarea;
      if(element) {
        element.style.height = element.scrollHeight + "px";
      }
    }
  }
}
</script>

<template>
  <div class="px-6 xl:px-20 py-8">
    <div class="text-4xl font-bold mb-4">{{ $t('overlay.iframe.header') }}</div>
    <p class="mb-6">
      {{ $t('overlay.iframe.text1') }}<br>
      {{ $t('overlay.iframe.text2') }}
    </p>

    <textarea disabled ref="textarea" class="shadow-textarea bg-white p-2.5 w-full max-w-['1024px'] mb-7 lg:mb-9">
      {{embedCode}}
      <script src="https://cdn.jsdelivr.net/npm/iframe-resizer@4.3.7/js/iframeResizer.min.js"></script>
      <script>
        iFrameResize({},'#iframe-{{ cardId }}')
      </script>
    </textarea>

    <button @click="copyCode()" class="bg-red-300 text-white flex space-x-2.5 items-center hover:shadow-info transition-shadow duration-200 mb-6">
      <span class="bg-red-600 h-8 px-1 flex items-center">
         <img src="@/assets/images/copy.svg" alt=" HTML-Code kopieren" width="20" height="20" loading="lazy">
      </span>
      <span class="py-1 pr-2">
        {{ $t('overlay.iframe.copy') }}
      </span>
    </button>

    <p class="mb-2.5 text-sm" v-html="$t('overlay.iframe.privacy')"></p>
    <p class="text-sm font-bold" v-show="codeCopied">{{ $t('overlay.iframe.copied') }}</p>
  </div>
</template>

<style>
  textarea {
    resize: none!important;
  }
</style>
