<script>
  // const assets with @ file for correct building with npm run build

  const hd_gruen = "./assets/dimensionen/gruen.svg"
  const hd_produktiv = "./assets/dimensionen/produktiv.svg"
  const hd_gerecht = "./assets/dimensionen/gerecht.svg"

  const sdg_1 = "./assets/sdg/SDG-icon-DE-01.svg"
  const sdg_2 = "./assets/sdg/SDG-icon-DE-02.svg"
  const sdg_3 = "./assets/sdg/SDG-icon-DE-03.svg"
  const sdg_4 = "./assets/sdg/SDG-icon-DE-04.svg"
  const sdg_5 = "./assets/sdg/SDG-icon-DE-05.svg"
  const sdg_6 = "./assets/sdg/SDG-icon-DE-06.svg"
  const sdg_7 = "./assets/sdg/SDG-icon-DE-07.svg"
  const sdg_8 = "./assets/sdg/SDG-icon-DE-08.svg"
  const sdg_9 = "./assets/sdg/SDG-icon-DE-09.svg"
  const sdg_10 = "./assets/sdg/SDG-icon-DE-10.svg"
  const sdg_11 = "./assets/sdg/SDG-icon-DE-11.svg"
  const sdg_12 = "./assets/sdg/SDG-icon-DE-12.svg"
  const sdg_13 = "./assets/sdg/SDG-icon-DE-13.svg"
  const sdg_14 = "./assets/sdg/SDG-icon-DE-14.svg"
  const sdg_15 = "./assets/sdg/SDG-icon-DE-15.svg"
  const sdg_16 = "./assets/sdg/SDG-icon-DE-16.svg"
  const sdg_17 = "./assets/sdg/SDG-icon-DE-17.svg"

  const sdg_1_en = "./assets/sdg/SDG-icon-EN-01.svg"
  const sdg_2_en = "./assets/sdg/SDG-icon-EN-02.svg"
  const sdg_3_en = "./assets/sdg/SDG-icon-EN-03.svg"
  const sdg_4_en = "./assets/sdg/SDG-icon-EN-04.svg"
  const sdg_5_en = "./assets/sdg/SDG-icon-EN-05.svg"
  const sdg_6_en = "./assets/sdg/SDG-icon-EN-06.svg"
  const sdg_7_en = "./assets/sdg/SDG-icon-EN-07.svg"
  const sdg_8_en = "./assets/sdg/SDG-icon-EN-08.svg"
  const sdg_9_en = "./assets/sdg/SDG-icon-EN-09.svg"
  const sdg_10_en = "./assets/sdg/SDG-icon-EN-10.svg"
  const sdg_11_en = "./assets/sdg/SDG-icon-EN-11.svg"
  const sdg_12_en = "./assets/sdg/SDG-icon-EN-12.svg"
  const sdg_13_en = "./assets/sdg/SDG-icon-EN-13.svg"
  const sdg_14_en = "./assets/sdg/SDG-icon-EN-14.svg"
  const sdg_15_en = "./assets/sdg/SDG-icon-EN-15.svg"
  const sdg_16_en = "./assets/sdg/SDG-icon-EN-16.svg"
  const sdg_17_en = "./assets/sdg/SDG-icon-EN-17.svg"

  const ic_1 = "./assets/cards/ic_arbeit_wirtschaft_120x120.svg"
  const ic_2 = "./assets/cards/ic_bruttoinlandsprodukt_80x80.svg"
  const ic_3 = "./assets/cards/ic_digitalisierung_120x120.svg"
  const ic_4 = "./assets/cards/ic_globale_verantwortungr_120x120.svg"
  const ic_5 = "./assets/cards/ic_kaufkraft_80x80.svg"
  const ic_6 = "./assets/cards/ic_klimaschutz_energie_120x120.svg"
  const ic_7 = "./assets/cards/ic_leben_wohnen_120x120.svg"
  const ic_8 = "./assets/cards/ic_mobiliteat_Infrastruktur_120x120.svg"
  const ic_9 = "./assets/cards/ic_partizipation_teilhabe_120x120.svg"
  const ic_10 = "./assets/cards/ic_umwelt_essourcenschutz_120x120.svg"
  const ic_11 = "./assets/cards/ic_verkehrstote_80x80.svg"
  const ic_12 = "./assets/cards/ic_wald_80x80.svg"
  const ic_13 = "./assets/cards/ic_wissenschaft_120x120.svg"
  const ic_14 = "./assets/cards/ic_wohnung_bestand_80x80.svg"
  const ic_15 = "./assets/cards/ic_wohnung_neu_80x80.svg"
  const ic_16 = "./assets/cards/ic_wohnung_qm_preis_80x80.svg"
  const ic_17 = "./assets/cards/ic_landbau_80c80.svg"
  const ic_18 = "./assets/cards/ic_verkehrstote_80x80.svg"

  const hf_partizipation_teilhabe = "./assets/handlungsfelder/partizipation_teilhabe.svg"
  const hf_digitalisierung = "./assets/handlungsfelder/digitalisierung.svg"
  const hf_wissenschaft = "./assets/handlungsfelder/wissenschaft.svg"
  const hf_arbeit_wirtschaft = "./assets/handlungsfelder/arbeit_wirtschaft.svg"
  const hf_globale_verantwortung = "./assets/handlungsfelder/globale_verantwortung.svg"
  const hf_leben_wohnen = "./assets/handlungsfelder/leben_wohnen.svg"
  const hf_mobilitaet_infrastruktur = "./assets/handlungsfelder/mobilitaet_infrastruktur.svg"
  const hf_umwelt_ressourcenschutz = "./assets/handlungsfelder/umwelt_ressourcenschutz.svg"
  const hf_klimaschutz_energie = "./assets/handlungsfelder/klimaschutz_energie.svg"

  const illu_digital = "./assets/cards/illu_digitale_infrastruktur_363x216.svg"
  const illu_fertige_wohnungen = "./assets/cards/illu_fertige_wohnungen_363x216.svg"
  const illu_ladepunkte = "./assets/cards/illu_ladepunkte_363x216.svg"
  const illu_oekologische_landwirtschaft = "./assets/cards/illu_oekologische_landwirtschaft_363x216.svg"
  const illu_parlamentarische_demokratie = "./assets/cards/illu_parlamentarische_demokratie_363x216.svg"
  const illu_wasserverbrauch = "./assets/cards/illu_wasserverbrauch_363x216.svg"
  const illu_wirschaftskraft = "./assets/cards/illu_wirtschaftskraft_363x216.svg"

  const lottie_1 = './assets/lottie/1.json'
  const lottie_2 = './assets/lottie/2.json'
  const lottie_3 = './assets/lottie/3.json'
  const lottie_4 = './assets/lottie/4.json'
  const lottie_5 = './assets/lottie/5.json'
  const lottie_6 = './assets/lottie/6.json'
  const lottie_7 = './assets/lottie/7.json'
  const lottie_8 = './assets/lottie/8.json'
  const lottie_9 = './assets/lottie/9.json'
  const lottie_10 = './assets/lottie/10.json'
  const lottie_11 = './assets/lottie/11.json'
  const lottie_12 = './assets/lottie/12.json'
  const lottie_13 = './assets/lottie/13.json'
  const lottie_14 = './assets/lottie/14.json'
  const lottie_15 = './assets/lottie/15.json'
  const lottie_16 = './assets/lottie/16.json'
  const lottie_17 = './assets/lottie/17.json'
  const lottie_18 = './assets/lottie/18.json'
  const lottie_19 = './assets/lottie/19.json'
  const lottie_20 = './assets/lottie/20.json'
  const lottie_21 = './assets/lottie/21.json'
  const lottie_22 = './assets/lottie/22.json'

  // add new assets here
  // mapping of const variable to assets.variable to use it in other components
  // just const the asset variable via const { assets } = "@/components/Assets.vue"
  const assets = {
    hf_partizipation_teilhabe: hf_partizipation_teilhabe,
    hf_digitalisierung: hf_digitalisierung,
    hf_wissenschaft: hf_wissenschaft,
    hf_arbeit_wirtschaft: hf_arbeit_wirtschaft,
    hf_globale_verantwortung: hf_globale_verantwortung,
    hf_leben_wohnen: hf_leben_wohnen,
    hf_mobilitaet_infrastruktur: hf_mobilitaet_infrastruktur,
    hf_umwelt_ressourcenschutz: hf_umwelt_ressourcenschutz,
    hf_klimaschutz_energie: hf_klimaschutz_energie,
    hd_gruen: hd_gruen,
    hd_produktiv: hd_produktiv,
    hd_gerecht: hd_gerecht,
    sdg_1: sdg_1,
    sdg_2: sdg_2,
    sdg_3: sdg_3,
    sdg_4: sdg_4,
    sdg_5: sdg_5,
    sdg_6: sdg_6,
    sdg_7: sdg_7,
    sdg_8: sdg_8,
    sdg_9: sdg_9,
    sdg_10: sdg_10,
    sdg_11: sdg_11,
    sdg_12: sdg_12,
    sdg_13: sdg_13,
    sdg_14: sdg_14,
    sdg_15: sdg_15,
    sdg_16: sdg_16,
    sdg_17: sdg_17,
    sdg_1_en: sdg_1_en,
    sdg_2_en: sdg_2_en,
    sdg_3_en: sdg_3_en,
    sdg_4_en: sdg_4_en,
    sdg_5_en: sdg_5_en,
    sdg_6_en: sdg_6_en,
    sdg_7_en: sdg_7_en,
    sdg_8_en: sdg_8_en,
    sdg_9_en: sdg_9_en,
    sdg_10_en: sdg_10_en,
    sdg_11_en: sdg_11_en,
    sdg_12_en: sdg_12_en,
    sdg_13_en: sdg_13_en,
    sdg_14_en: sdg_14_en,
    sdg_15_en: sdg_15_en,
    sdg_16_en: sdg_16_en,
    sdg_17_en: sdg_17_en,
    ic_1: ic_1,
    ic_2: ic_2,
    ic_3: ic_3,
    ic_4: ic_4,
    ic_5: ic_5,
    ic_6: ic_6,
    ic_7: ic_7,
    ic_8: ic_8,
    ic_9: ic_9,
    ic_10: ic_10,
    ic_11: ic_11,
    ic_12: ic_12,
    ic_13: ic_13,
    ic_14: ic_14,
    ic_15: ic_15,
    ic_16: ic_16,
    ic_17: ic_17,
    ic_18: ic_18,
    illu_digital: illu_digital,
    illu_fertige_wohnungen: illu_fertige_wohnungen,
    illu_ladepunkte: illu_ladepunkte,
    illu_oekologische_landwirtschaft: illu_oekologische_landwirtschaft,
    illu_parlamentarische_demokratie: illu_parlamentarische_demokratie,
    illu_wasserverbrauch: illu_wasserverbrauch,
    illu_wirschaftskraft: illu_wirschaftskraft,
  }

  const lottie = {
      lottie_1,
      lottie_2,
      lottie_3,
      lottie_4,
      lottie_5,
      lottie_6,
      lottie_7,
      lottie_8,
      lottie_9,
      lottie_10,
      lottie_11,
      lottie_12,
      lottie_13,
      lottie_14,
      lottie_15,
      lottie_16,
      lottie_17,
      lottie_18,
      lottie_19,
      lottie_20,
      lottie_21,
      lottie_22
  }

  export {
    assets,
    lottie
  }
</script>
