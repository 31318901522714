<script>

import Translation from "@/i18n/translation";
import {defineComponent} from "vue";

export default defineComponent({
  computed: {
    Translation() {
      return Translation
    }
  }
})

</script>

<template>
  <div class="container">
    <h1 class="text-4xl lg:text-5xl text-black font-bold mb-6 hyphens-auto">{{ $t("intro.header") }}</h1>

    <div class="lg:grid lg:grid-cols-3 lg:gap-10 mb-10 lg:mb-14">
      <div class="col-span-2">
        <h2 class="text-3xl text-black font-bold mb-6 lg:mb-12 hyphens-auto">{{ $t("intro.subheader") }}</h2>
        <img v-if="Translation.currentLocale === 'de'" alt="Ziele für nachhaltige Entwicklung" class="lg:hidden mb-6 block" src="@/assets/images/sdg-logo.png" width="299" height="90" loading="lazy"/>
        <img v-if="Translation.currentLocale === 'en'" alt="Sustainable Development Goals" class="lg:hidden mb-6 block" src="@/assets/images/sdg-logo-en.png" width="299" height="90" loading="lazy"/>

        <p class="text-xl text-black mb-5">{{ $t("intro.text_1") }}</p>
        <p class="text-xl text-black mb-5">{{ $t("intro.text_2") }}</p>
        <p class="text-xl text-black mb-10">{{ $t("intro.text_3") }}</p>
      </div>
      <div class="col-span-1">
        <img v-if="Translation.currentLocale === 'de'" alt="Ziele für nachhaltige Entwicklung" class="hidden lg:block mb-10" src="@/assets/images/sdg-logo.png" width="364" height="109" loading="lazy"/>
        <img v-if="Translation.currentLocale === 'en'" alt="Sustainable Development Goals" class="hidden lg:block mb-10" src="@/assets/images/sdg-logo-en.png" width="364" height="109" loading="lazy"/>
        <img alt="" class="hidden lg:block" src="@/assets/images/intro-image-2.png" width="329" height="362"  loading="lazy"/>
      </div>
    </div>
  </div>
</template>
