<script>
export default {
  data() {
    return {
      hover: false,
    };
  }
}
</script>

<template>
  <div @mouseover="hover = true"
       @mouseleave="hover = false">
    <svg v-show="!hover" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="32.141"
         height="32.141" viewBox="0 0 32.141 32.141">
      <defs>
        <clipPath id="rss-clip-path">
          <rect width="32.141" height="32.141" fill="none"/>
        </clipPath>
      </defs>
      <g clip-path="url(#rss-clip-path)">
        <path
            d="M26.114,0H6.026A6.027,6.027,0,0,0,0,6.026V26.114a6.027,6.027,0,0,0,6.026,6.026H26.114a6.027,6.027,0,0,0,6.026-6.026V6.026A6.027,6.027,0,0,0,26.114,0M8.035,28.123a4.018,4.018,0,1,1,4.018-4.018,4.018,4.018,0,0,1-4.018,4.018m8.035,0A12.053,12.053,0,0,0,4.018,16.07V12.053a16.071,16.071,0,0,1,16.07,16.07Zm8.035,0A20.087,20.087,0,0,0,4.018,8.035V4.018A24.105,24.105,0,0,1,28.123,28.123Z"
            transform="translate(0 0)" fill="#707070"/>
      </g>
    </svg>

    <svg v-show="hover" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="32.141" height="32.141" viewBox="0 0 32.141 32.141">
      <defs>
        <clipPath id="rss-hover-clip-path">
          <rect width="32.141" height="32.141" fill="orange"/>
        </clipPath>
      </defs>
      <g clip-path="url(#rss-hover-clip-path)">
        <path d="M26.114,0H6.026A6.027,6.027,0,0,0,0,6.026V26.114a6.027,6.027,0,0,0,6.026,6.026H26.114a6.027,6.027,0,0,0,6.026-6.026V6.026A6.027,6.027,0,0,0,26.114,0M8.035,28.123a4.018,4.018,0,1,1,4.018-4.018,4.018,4.018,0,0,1-4.018,4.018m8.035,0A12.053,12.053,0,0,0,4.018,16.07V12.053a16.071,16.071,0,0,1,16.07,16.07Zm8.035,0A20.087,20.087,0,0,0,4.018,8.035V4.018A24.105,24.105,0,0,1,28.123,28.123Z" transform="translate(0 0)" fill="orange"/>
      </g>
    </svg>

  </div>
</template>
