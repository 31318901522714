<script>
  import {mapState} from "vuex";

  export default {
    computed: {
      ...mapState({
        overlayData: 'overlayData',
      })
    },
  }
</script>

<template>
  <div v-if="overlayData.download" class="px-6 xl:px-20 pb-8 flex flex-col gap-4">
    <div class="font-bold text-2xl">{{$t('overlay.download')}}</div>
    <a href="#" class="flex flex-row gap-2">
      <svg id="ic_download" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <g id="Gruppe_4164" data-name="Gruppe 4164" transform="translate(1 0)">
          <path id="Pfad_5909" data-name="Pfad 5909" d="M20.275,74v3.77H1.752V74H0v5.523H22.027V74Z" transform="translate(0 -55.523)" fill="#e30613"/>
          <path id="Pfad_5910" data-name="Pfad 5910" d="M19.614,0V15.249l-6.4-6.4-1.239,1.239L20.49,18.6l8.517-8.517L27.768,8.847l-6.4,6.4V0Z" transform="translate(-8.976)" fill="#e30613"/>
        </g>
        <rect id="Rechteck_134" data-name="Rechteck 134" width="24" height="24" transform="translate(0 0)" fill="none"/>
      </svg>
      <span>{{$t('download.name')}}</span>
      <span class="text-gray-400">({{$t('download.size')}})</span>
    </a>
  </div>
</template>
