<script>
import Translation from "@/i18n/translation"
import Globe from "@/components/icons/Globe.vue"
import Instagram from "@/components/icons/Instagram.vue"
import Youtube from "@/components/icons/Youtube.vue"
import Facebook from "@/components/icons/Facebook.vue"
import Twitter from "@/components/icons/Twitter.vue"
import Rss from "@/components/icons/Rss.vue"

export default {
  components: {
    Globe,
    Instagram,
    Youtube,
    Facebook,
    Twitter,
    Rss
  },
  data: () => {
    return {};
  },
  setup() {
    return {Translation}
  }
};
</script>

<template>
  <footer>
    <div class="container text-center md:text-left">
      <hr class="my-9 border border-gray-400"/>

      <div class="max-w-[287px] md:max-w-none mx-auto mb-9">
        <div class="mx-auto max-w-[195px] md:max-w-none grid md:grid-cols-4 gap-9 lg:gap-11">
          <div class="flex items-center justify-start">
            <img alt="Gefördert durch Engagement Global" class="" src="@/assets/images/logo-engagement-global.svg"
                 width="194"
                 height="57"
                 loading="lazy"/>
          </div>

          <div class="flex items-center justify-center">
            <img alt="mit ihrer Servicestelle Kommunen in der einen Welt" class=""
                 src="@/assets/images/logo-servicestelle.svg" width="194"
                 height="57" loading="lazy"/>
          </div>

          <div class="flex items-center justify-center">
            <img alt="mit Mitteln des Bundesministerium für wirtschaftiche Zusammenarbeit und Entwicklung" class=""
                 src="@/assets/images/logo-bundesministerium.svg" width="194"
                 height="57" loading="lazy"/>
          </div>

          <div class="flex items-center justify-end">
            <img alt="Europäischer Fonds für regionale Entwicklung" class=""
                 src="@/assets/images/logo-react-eu.svg" width="194"
                 height="57" loading="lazy"/>
          </div>
        </div>
      </div>
    </div>

    <div class="bg-gray-200 pt-9 pb-8 md:pt-11 md:pb-10">
      <div class="container text-center md:text-left">
        <div class="max-w-[287px] md:max-w-none mx-auto">
          <div
              class="md:flex flex-wrap md:justify-center md:space-x-5 xl:space-x-0 xl:grid xl:grid-cols-6 space-y-5 md:space-y-0 text-black">
            <div>
              <RouterLink class="hover:text-red-300 transition-colors duration-200"
                          :to="Translation.i18nRoute({ name: Translation.currentLocale + '.imprint' })"
                          :title='$t("footer.imprint")'>{{ $t("footer.imprint") }}
              </RouterLink>
            </div>
            <div>
              <RouterLink class="hover:text-red-300 transition-colors duration-200"
                          :to="Translation.i18nRoute({ name: Translation.currentLocale + '.privacy' })"
                          :title='$t("footer.privacy")'>{{ $t("footer.privacy") }}
              </RouterLink>
            </div>
            <div>
              <a class="group hover:text-red-300 transition-colors duration-200" href="https://www.regensburg.de/"
                 target="_blank" title="Stadt Regensburg">
                <Globe></Globe>
                <span class="ml-2">regensburg.de</span>
              </a>
            </div>
            <div>
              <a class="group hover:text-red-300 transition-colors duration-200" href="https://mein.regensburg.de/"
                 target="_blank"
                 title="Öffentlichkeitsbeteiligung in der Stadt Regensburg">
                <Globe></Globe>
                <span class="ml-2">mein.regensburg.de</span>
              </a>
            </div>
            <div class="basis-full lg:col-span-2 flex justify-center xl:justify-end pt-7 md:pt-12 xl:pt-0">
              <div class="flex flex-wrap space-x-5">
                <a href="https://www.facebook.com/regensburg.de" target="_blank" title="Facebook">
                  <Facebook/>
                </a>
                <a href="https://twitter.com/stadtregensburg" target="_blank" title="Twitter">
                  <Twitter/>
                </a>
                <a href="https://www.instagram.com/stadtregensburg/" target="_blank" title="Instagram">
                  <Instagram/>
                </a>
                <a href="https://www.youtube.com/stadtregensburg" target="_blank" title="Youtube">
                  <Youtube/>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<style scoped>

</style>
