<script>
import {defineAsyncComponent} from 'vue'

const Cards = defineAsyncComponent(() =>
    import('@/components/Cards.vue')
)
import Intro from '@/components/Intro.vue'
import Filter from '@/components/Filter.vue'
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: 'HomeView',
  inject: ['jsonData'],
  data: () => {
    return {
      initialized: false
    }
  },
  components: {
    Header,
    Footer,
    Intro,
    Filter,
    Cards
  },
  watch: {
    jsonData(value) {
      if (value) {
        this.initialized = true;
      }
    }
  },
}
</script>


<template>

  <Header></Header>
  <main class="overflow-x-hidden pt-7 md:pt-12">
    <Intro/>

    <Filter/>

    <Cards/>
  </main>
  <Footer></Footer>
</template>
