<script>
import HeaderNavigation from '@/components/navigation/HeaderNavigation.vue'
import LanguageSwitcher from '@/components/navigation/LanguageSwitcher.vue'
import Translation from "@/i18n/translation"

export default {
  components: {
    HeaderNavigation,
    LanguageSwitcher
  },
  data: () => {
    return {};
  },
  setup() {
    return { Translation }
  }
};
</script>

<template>
  <header class="h-30 md:h-[192px] shadow-header">
    <div class="container h-full flex items-center">
      <router-link :to="Translation.i18nRoute({ name: Translation.currentLocale + '.home' })">
        <img alt="Stadt Regensburg" class="logo md:w-[190px]" src="@/assets/images/logo.svg" width="140" height="68" />
      </router-link>

      <div class="ml-auto mt-10 md:mt-0 md:mb-12 flex space-x-6 md:space-x-8 items-end">
        <HeaderNavigation/>
        <LanguageSwitcher/>
      </div>
    </div>
  </header>
</template>
